import React from "react"
import SEO from "../components/seo"
import {
  AppleAppStore,
  GooglePlayStore,
  MagnusCenterLogo,
  MagnusHeaderLogo,
} from "../components/svg-images"
import "../styles/get-started-with-hawki.sass"

const GetStartedWithHawki = () => {
  return (
    <div className="hawki-landing-container">
      <SEO title="Get Started With HAWKi" />
      <div className="hl-wrapper">
        <div className="hl-header">
          <MagnusHeaderLogo />
        </div>
        <div className="hl-content">
          <div className="hl-td">
            <p className="hl-title">Download our App to control your tank!</p>
          </div>
          <div className="hl-store-links">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.magnusmonitors.user"
            >
              <GooglePlayStore />
            </a>
            <div className="hl-store-links-separator" />
            <a target="_blank" href="https://apps.apple.com/app/id1485215538">
              <AppleAppStore />
            </a>
          </div>
        </div>
        <div className="phone-preview">
          <img
            src={require("../images/iphone-preview.png")}
            alt="phone-preview"
          />
        </div>
      </div>
      <div className="hl-bg-logo-wrapper">
        <div className="hl-bg-logo">
          <MagnusCenterLogo />
        </div>
      </div>
    </div>
  )
}

export default GetStartedWithHawki
